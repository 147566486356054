import React from 'react';
import Container from 'react-bootstrap/Container';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import * as checkoutActions from '../actions/checkout';
import { renderTypeaheadInput } from '../components/Form/RenderForm';
import { IAppState } from '../reducers';
import { required } from '../validation';

const Home: React.FC<RouterProps> = props => {
  const dispatch = useDispatch();
  const commonState = useSelector((appState: IAppState) => appState.common);

  return (
    <main className="hero bg-primary text-light py-5 page_home">
      <Container className="pt-5">
        <div className="row pt-5">
          <div className="col-lg-8 6 pt-5">
            <h1 className="d-none">Reliance Telephone</h1>
            <span className="page-watermark rt-wtrmrk"></span>
            <h2 className="pb-3">With Reliance, you have more flexible options to connect with your friends and family...</h2>
            <div className="d-flex">
              <h4 className="pr-4"> &nbsp; &nbsp; &nbsp; &nbsp; by texting, voice calls and video calls.</h4>
            </div>
            <div className="border border-light rounded px-5 py-4 mt-5">
              <h4 className="pb-2">Get started by finding a facility</h4>
              <p>Search by state, city, county, or facility name</p>

              <Form
                onSubmit={({facility}) => {
                }}>
                {form =>
                  <form onSubmit={form.handleSubmit} method="post">
                    <div className="row no-gutters">
                      <div className="form-label-group col-md-12">
                        {renderTypeaheadInput(
                          'facility',
                          'facility',
                          commonState.loadingFacilities ? 'Loading facilities...' : 'Search for facility...',
                          null,
                          commonState.facilities ? commonState.facilities.map(f => ({id: f.id, label: `${f.name} (${f.city}, ${f.state})`})) : [],
                          [required],
                          commonState.loadingFacilities,
                          value => {
                            if (value.length > 0) {
                              dispatch(checkoutActions.selectFacilityById(value[0].id));
                              props.history.push('/checkout/service');
                            }
                          }
                        )}
                        {commonState.error &&
                          <p className="text-error subtitle errormessage py-1">
                            An error occurred while loading facilities
                          </p>
                        }
                      </div>
                    </div>
                  </form>
                }
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </main>
  );
}

export default withRouter(Home);
