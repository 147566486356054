import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IWalletPayment } from '../models/WalletPayment';
import { IAppState } from '../reducers';
import { index } from '../resources/WalletPayment';
import { IPaginatedResponse } from '../services/api';

export interface IWalletHistoryLoadAction {
  type: typeof LOADING
  history: IPaginatedResponse<IWalletPayment>
}

export const LOADING = 'WALLET_HISTORY/LOADING';
export const LOAD_SUCCESS = 'WALLET_HISTORY/LOAD_SUCCESS';
export const LOAD_FAILURE = 'WALLET_HISTORY/LOAD_FAILURE';
export function load(): ThunkAction<Promise<IPaginatedResponse<IWalletPayment>>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: LOADING });

    return index()
      .then((paginatedResponse): IPaginatedResponse<IWalletPayment> => {
        dispatch({ type: LOAD_SUCCESS, history: paginatedResponse });
        return paginatedResponse;
      })
      .catch(error => {
        dispatch({ type: LOAD_FAILURE, error });
        throw error;
      })
  }
}