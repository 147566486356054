import { IPersonalInformationData } from '../components/Checkout/PersonalInformation';
import { ICreditCardData } from '../models/CreditCard';
import { IWalletPayment } from '../models/WalletPayment';
import Api, { IPaginatedResponse } from '../services/api';

export function index(page = 1, perPage = 250) {
  return Api
    .get<IPaginatedResponse<IWalletPayment>>({
      url: '/inmate-account-payment',
      query: {page, perPage}
    });
}

interface IPaymentRequestData {
  facilityId: number
  inmateId: number
  amount: number
  receiptEmailAddress: string
}

interface IWalletPaymentRequestData {
  payment: IPaymentRequestData
  cardSecurityCode: string
  savedCreditCardId?: number
  newCreditCard: ICreditCardData
  purchaserDetails: IPersonalInformationData
}

interface IWalletPaymentResponse {
  success: boolean
  payment: IWalletPayment
}

export function post(paymentRequest: IWalletPaymentRequestData) {
  return Api
    .post<IWalletPaymentResponse>({
      url: '/inmate-account-payment',
      data: paymentRequest
    })
}

export function get(id: number) {
  return Api
    .get<IWalletPayment>({
      url: `/inmate-account-payment/${id}`
    })
}