/// <reference path="./custom.d.ts" />

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import '../css/custom.scss';
import * as websiteActions from './actions/website';
import Accounts from './components/Authenticated/Account/Accounts';
import AccountView from './components/Authenticated/Account/View';
import CheckoutCardReceipt from './components/Authenticated/Card/Receipt';
import CheckoutCollectReceipt from './components/Authenticated/Collect/Receipt';
import Dashboard from './components/Authenticated/Dashboard/Dashboard';
import PhoneCardPurchaseHistory from './components/Authenticated/PhoneCard/History';
import Settings from './components/Authenticated/Settings/Settings';
import VisitationScheduled from './components/Authenticated/Visitation/Scheduled';
import WalletHistory from './components/Authenticated/Wallet/History';
import CheckoutWalletReceipt from './components/Authenticated/Wallet/Receipt';
import CheckoutCard from './components/Checkout/Card';
import CheckoutCollect from './components/Checkout/Collect';
import CheckoutFacility from './components/Checkout/Facility';
import CheckoutService from './components/Checkout/Service';
import CheckoutWallet from './components/Checkout/Wallet';
import ErrorBoundary from './components/ErrorBoundary';
import Summary from './components/Facility/Summary';
import Footer from './components/Footer';
import Header from './components/Header';
import ForgotPassword from './components/Login/ForgotPassword';
import Logout from './components/Login/Logout';
import Maintenance from './components/Maintenance';
import UpcomingMaintenance from './components/UpcomingMaintenance';
import VisitationSchedule from './components/Visitation/Schedule';
import About from './containers/About';
import Apps from './containers/Apps';
import Contact from './containers/Contact';
import Home from './containers/Home';
import Login from './containers/Login';
import Policies from './containers/Policies';
import Services from './containers/Services';
import Support from './containers/Support';
import Troubleshooting from './containers/Troubleshooting';
import { IAppState } from './reducers';
import { appStore, persistor } from './store';

const upcomingMaintenance = false;
const upcomingMaintenanceStartTimes = 'Wednesday 3am Eastern/2am Central';
const upcomingMaintenanceEndTimes = 'Wednesday 9am Eastern/8am Central';

const underMaintenance = false;
const maintenanceEndTimes = '9am Eastern/8am Central';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Page: React.FC = () => {
  const [showUpcomingMaintenance, setShowUpcomingMaintenance] = useState(upcomingMaintenance);

  const state = useSelector((appState: IAppState) => ({
    auth: appState.auth,
    common: appState.common
  }));

  // Retrieve the base website data
  const dispatch = useDispatch();
  useEffect(() => {
    if (state.common.rehydrated && state.common.facilities == null) {
      dispatch(websiteActions.loadBaseData());
    }
  }, [state.common.rehydrated]);

  return (
    <Router>
      <ScrollToTop /> {/* Scrolls the page to the top whenever the path changes */}

      {underMaintenance ?
        <Maintenance endTimes={maintenanceEndTimes} /> :
        <ErrorBoundary>
          <Header errorMode={false} />

          {showUpcomingMaintenance &&
            <UpcomingMaintenance
              startTimes={upcomingMaintenanceStartTimes}
              endTimes={upcomingMaintenanceEndTimes}
              onClose={() => setShowUpcomingMaintenance(false)}
              />
          }

          <Switch>
            {state.auth.loggedIn && <Route path='/account/dashboard' component={Dashboard} exact />}
            {state.auth.loggedIn && <Route path='/account/settings' component={Settings} exact />}
            {state.auth.loggedIn && <Route path='/accounts/:ani' render={props => <AccountView ani={props.match.params.ani} />} exact />}
            {state.auth.loggedIn && <Route path='/accounts' component={Accounts} exact />}
            {state.auth.loggedIn && <Route path='/card/history' component={PhoneCardPurchaseHistory} exact />}
            {state.auth.loggedIn && <Route path='/visitation/scheduled' component={VisitationScheduled} exact />}
            {state.auth.loggedIn && <Route path='/wallet/history' component={WalletHistory} exact />}

            <Route path='/checkout/facility' component={CheckoutFacility} />
            <Route path='/checkout/service' component={CheckoutService} />
            <Route path='/checkout/collect/receipt/:id' component={CheckoutCollectReceipt} />
            <Route path='/checkout/collect' component={CheckoutCollect} />
            <Route path='/checkout/card/receipt/:id' component={CheckoutCardReceipt} />
            <Route path='/checkout/card' component={CheckoutCard} />
            <Route path='/checkout/wallet/receipt/:id' component={CheckoutWalletReceipt} />
            <Route path='/checkout/wallet/:ani?' component={CheckoutWallet} />
            <Route path='/visitation/schedule' component={VisitationSchedule} />

            <Route path='/about' component={About} />
            <Route path='/app/:id?' component={Apps} />
            <Route path='/services' component={Services} />
            <Route path='/contact' component={Contact} />
            <Route path='/support' component={Support} />
            <Route path='/troubleshooting' component={Troubleshooting} />
            <Route path='/policies' component={Policies} />
            <Route path='/facility/:state/:name' component={Summary} />
            <Route path='/login' component={Login} exact />
            <Route path='/login/forgot-password/:email?/:resetCode?' component={ForgotPassword} />
            <Route path='/logout' component={Logout} />
            <Route path='/' component={Home} />
            <Redirect path="*" to="/login" />
          </Switch>
        </ErrorBoundary>
      }

      <Footer />
    </Router>
  )
}

const App = () =>
  <Provider store={appStore}>
    <PersistGate loading={null} persistor={persistor}>
      <Page />
    </PersistGate>
  </Provider>

ReactDOM.render(
  <App/>,
  document.getElementById('app')
);

export default App;