import * as walletPaymentResource from '../../resources/WalletPayment';

export const LOAD_PAYMENT_LOADING = 'WALLET/RECEIPT/LOAD_PAYMENT_LOADING';
export const LOAD_PAYMENT_SUCCESS = 'WALLET/RECEIPT/LOAD_PAYMENT_SUCCESS';
export const LOAD_PAYMENT_FAILURE = 'WALLET/RECEIPT/LOAD_PAYMENT_FAILURE';
export function loadPayment(id: number|string) {
  return dispatch => {
    dispatch({ type: LOAD_PAYMENT_LOADING, id });

    return walletPaymentResource
      .get(Number(id))
      .then(payment => dispatch({ type: LOAD_PAYMENT_SUCCESS, payment}))
      .catch(error => dispatch({ type: LOAD_PAYMENT_FAILURE, error }))
  }
}

export const RESET = 'WALLET/RECEIPT/RESET';
export function reset() {
  return { type: RESET };
}
