import { CallType } from './Call';
import { IRatePlan } from './RatePlan';

export enum FacilityFeature {
  VideoCalls = 'Video Calls',
  CommissaryOrdering = 'Commissary Ordering',
  KioskAudioCalls = 'Kiosk Audio Calls',
  WirelessAudioCalls = 'Wireless Audio Calls',
  WirelessVideoCalls = 'Wireless Video Calls',
  WirelessVideoVisitation = 'Wireless Video Visitations',
  PrepaidCollectCalls = 'Prepaid Collect Calls'
}

export interface IFacilityName {
  name: string
  address: string
  city: string
  state: string
  zip: string
}

export interface IDebitCardFacility extends IFacilityName {
  id: number
  directBillFacility: IFacility
  privatePin: boolean
  allowPinSales: boolean
}

export interface IFacilityConfiguration {
  id: number
  key: string
  value: string
}

export interface IFacility extends IFacilityName {
  id: number

  visitScheduleWindowMinHours: number
  visitScheduleWindowMaxHours: number
  visitorTerms: string

  features: FacilityFeature[]
  featureTexting: boolean
  visitationSchedulingEnabled: boolean
  phoneCardRate: number
  phoneCardsEnabled: boolean

  latitude: number
  longitude: number
  timezone: string

  publicRoster: boolean

  configurations: IFacilityConfiguration[]

  voicemail: string
  voicemailExtension: string

  ratePlan: IRatePlan

  active: boolean

  getConfigurationValue?: (key: string) => any
  getCollectLocalCallRate?: () => number
  getCollectInStateCallRate?: () => number
  getCollectOutOfStateCallRate?: () => number
}

function stringToBoolean(val: string) {
  switch(val.toLowerCase()) {
    case "true": case "1":
      return true;
    default: return false;
  }
}

export class Facility implements IFacility {
  id: number

  visitScheduleWindowMinHours: number
  visitScheduleWindowMaxHours: number
  visitorTerms: string

  features: FacilityFeature[]
  featureTexting: boolean
  visitationSchedulingEnabled: boolean
  phoneCardRate: number
  phoneCardsEnabled: boolean

  configurations: IFacilityConfiguration[]

  voicemail: string
  voicemailExtension: string

  name: string
  address: string
  city: string
  state: string
  zip: string

  ratePlan: IRatePlan

  publicRoster: boolean

  latitude: number
  longitude: number
  timezone: string

  active: boolean

  constructor(data: IFacility) {
    this.id = data.id;
    this.visitScheduleWindowMinHours = data.visitScheduleWindowMinHours;
    this.visitScheduleWindowMaxHours = data.visitScheduleWindowMaxHours;
    this.visitorTerms = data.visitorTerms;
    this.features = data.features;
    this.featureTexting = data.featureTexting;
    this.visitationSchedulingEnabled = stringToBoolean((data.visitationSchedulingEnabled||'false').toString());
    this.phoneCardRate = data.phoneCardRate;
    this.phoneCardsEnabled = data.phoneCardsEnabled == true;
    this.configurations = data.configurations;
    this.name = data.name;
    this.address = data.address;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.timezone = data.timezone;
    this.publicRoster = data.publicRoster == true; // It may be null
    this.voicemail = data.voicemail;
    this.voicemailExtension = data.voicemailExtension;
    this.active = data.active;
    this.ratePlan = data.ratePlan;
  }

  getConfigurationValue(key: string) {
    const matches = this.configurations.filter(c => c.key.toLowerCase() == key.toLowerCase());
    if (matches.length > 0) {
      return matches[0].value;
    }
    return null;
  }

  getCollectLocalCallRate() {
    const matches = (this.ratePlan.rates||[]).filter(r => r.callType == CallType.Local);
    if (matches.length > 0) {
      return matches[0].perMinuteCharge;
    }
    return 0.50;
  }

  getCollectInStateCallRate() {
    const matches = (this.ratePlan.rates||[]).filter(r => r.callType == CallType.InState);
    if (matches.length > 0) {
      return matches[0].perMinuteCharge;
    }
    return 0.50;
  }

  getCollectOutOfStateCallRate() {
    const matches = (this.ratePlan.rates||[]).filter(r => r.callType == CallType.OutOfState);
    if (matches.length > 0) {
      return matches[0].perMinuteCharge;
    }
    return 0.50;
  }
}

export function isFacility(data: Facility|IFacility): data is Facility {
  return (data as Facility).getConfigurationValue !== undefined;
}